import { LocationType } from "@/common/enums";

export class LocationDTO {
  locationCode = "";
  name = "";
  partner = "";
  mainLocation = false;
  type = LocationType.LOCATION;
  hasApiIntegration = false;
  hasDocument = false;

  constructor(name = "") {
    this.name = name;
  }
}
