import { Country, Title } from "@/common/enums";

export class AddressDTO {
  addressId = "";

  title = Title.NONE;

  company = "";

  firstName = "";
  lastName = "";

  street1 = "";
  street2 = "";
  buildingNo = "";

  zip = "";
  city = "";
  country = Country.NONE;

  email = "";
  phone = "";
  fax = "";
}
