import { CaseDTO, OverviewItemDTO } from "@/common/dto";
import { DamageType, Title } from "@/common/enums";
import moment from "moment";
import i18n from "@/ui/i18n";

export class DtoHelpers {
  static generate_case_title(aCase: CaseDTO): string {
    let name = "";
    if (
      aCase.injuredParty !== null &&
      aCase.injuredParty !== undefined &&
      (aCase.injuredParty.lastName !== "" || aCase.injuredParty.company !== "")
    ) {
      if (aCase.injuredParty.title === Title.COMPANY) {
        name = aCase.injuredParty.company;
      } else {
        name = aCase.injuredParty.lastName;
      }
    }

    return this.generate_title(
      name,
      aCase.vehicleLicensePlate,
      aCase.accidentDate,
      aCase.damageType
    );
  }

  static generate_overview_title(item: OverviewItemDTO): string {
    const name = item.company !== "" ? item.company : item.lastName;

    return this.generate_title(
      name,
      item.vehicleLicensePlate,
      item.accidentDate,
      item.damageType
    );
  }

  static generate_title(
    name: string,
    licensePlate: string,
    accidentDate: Date | null,
    damageType: DamageType
  ): string {
    const items = [] as string[];

    if (name !== "") {
      items.push(name);
    }

    if (licensePlate !== "") {
      items.push(licensePlate);
    }

    const date = moment(accidentDate);
    if (date.isValid()) {
      items.push(date.format("DD.MM.YYYY"));
    } else {
      items.push(i18n.t("UI.general.dateInvalid"));
    }

    if (damageType !== undefined && damageType !== DamageType.NONE) {
      items.push(i18n.t("UI.enum.damage_type." + damageType));
    }

    return items.join(" - ");
  }
}
