import {
  Advisory,
  Country,
  DamageType,
  DecisionEnum,
  Kind,
  Ownership,
  RentalDecision,
  RepairDecision,
  Reporter,
  Roadworthiness,
  Status,
} from "@/common/enums";
import { AddressDTO, LocationDTO } from "@/common/dto";

export class CaseDTO {
  id = "";
  title = "";
  locationId = "";
  createdBy = "";
  creationDate = new Date();
  lastModifiedBy = "";
  lastModifiedDate = new Date();
  lastModifiedDateHistory = new Date();
  fileSign = "";
  fileSignLawyer = "";
  status = Status.WORK_IN_PROGRESS;
  reporter = Reporter.NONE;
  kind = Kind.NONE;
  injuredParty = new AddressDTO();
  injuredIban = "";
  injuredVatDeduction = DecisionEnum.NONE;
  legalInsurance = "";
  vehicleLicensePlate = "";
  vehicleInsuranceId = "";
  vehicleOwnership = Ownership.NONE;
  vehicleBank = "";
  roadworthy = Roadworthiness.NONE;
  opponentInsuranceId = "";
  opponentClaimNumber = "";
  insurancePolicy = "";
  opponentOwnerAddress = new AddressDTO();
  driverIsOwner = DecisionEnum.NONE;
  opponentDriverAddress = new AddressDTO();
  opponentLicensePlate = "";
  opponentLicensePlateCountry = Country.NONE;
  accidentDate: Date | null = null;
  accidentPlace = "";
  accidentCourse = "";
  accidentNational = DecisionEnum.NONE;
  accidentCountry = Country.NONE;
  policeAlerted = DecisionEnum.NONE;
  policeInspection = "";
  policeReference = "";
  insuranceContacted = DecisionEnum.NONE;
  rentalWanted = RentalDecision.NONE;
  repairWanted = RepairDecision.NONE;
  repairDate: Date | null = null;
  witnessAvailable = DecisionEnum.NONE;
  advisory = Advisory.NONE;
  expertOffice = "";
  expertEmployee = "";
  expertReference = "";
  witnessAddress = new AddressDTO();
  additionalInfos = "";
  physicalInjury = DecisionEnum.NONE;
  version = 0;
  damageType = DamageType.THIRD_PARTY_LIABILITY;
  tags: string[] = [];
  customerReference = "";
  vehicleId = "";
  legalInsuranceId = "";
  legalInsuranceAvailable = DecisionEnum.NONE;
  caseHandler = "";
  releaseDate: Date | null = null;
  carManufacturer = "";
  carModel = "";
  selfInvolvementPartial = "";
  selfInvolvementFull = "";
  expertOfficeCode = new LocationDTO();
  expertEmployeeCode = "";
}
